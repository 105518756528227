<template>
  <div class="ma-0 pa-0">
    <!--
    <div class="main_image">
      <v-img class="img" src="https://s3.ap-northeast-2.amazonaws.com/images.wintersleeping/intro_main+(1).jpeg" />
    </div>
    -->
    <v-card
      flat
      tile
      v-for="each in notice" :key="each.id"
      style="position:absolute; top:5px; right:10px; border-left:3px solid #d98b0d; font-size:0.8em;"
      class="pa-2 ma-0"
      color="#eeeeee">
      <v-icon color="#d98b0d">info</v-icon> {{ each.data.title }}
    </v-card>

    <div class="main_image">
      <v-row dense>
        <v-col md="8" cols="12" :class="{'center':!mobile, 'mr-0':true, 'ml-0':true, 'center2': mobile}" align="center">
          동면중의 운동도우미
        </v-col>
        <v-col md="4" cols="12"  :class="{'center':!mobile, 'mr-0':true, 'ml-0':true, 'center2': mobile}" align="center">
          <v-img :width="mobile?200:400" src="https://s3.ap-northeast-2.amazonaws.com/images.wintersleeping/main_pic.png" />
        </v-col>
      </v-row>
    </div>

    <div class="main_desc">
      <v-row align="center" justify="center" >
        <v-col cols="12" class="pa-0">
          <v-row dense>
            <v-col cols="12" align="center" >

              <!--

              <v-row no-gutters align="start" justify="start">

                <v-card flat class="doc ma-0" v-model="notice_list" style="overflow:hidden" color="rgba(0,0,0,0.7)">

                  <v-window v-model="window" class="elevation-0" :show-arrows="true">
                    <v-window-item v-for="(notice, n) in notice_list" :key="n">
                      <v-card flat color="transparent">
                        <v-card-text class="white--text" align="start">
                          <div class="subtitle-2 mb-2">{{ notice.title }}</div>
                          <div class="caption" v-html="notice.br_content"></div>
                        </v-card-text>
                      </v-card>
                    </v-window-item>
                  </v-window>

                </v-card>
              </v-row>

              -->

              <v-row no-gutters align="start" justify="center">
                <v-col md="4" cols="12">
                  <div>
                    <v-img src="https://s3.ap-northeast-2.amazonaws.com/images.wintersleeping/workout.jpeg" />
                    <v-card-title class="headline">
                      운동도우미
                    </v-card-title>
                    <v-card-text>
                      <p>
                      체중, 체지방률, 활동 정보, 식단 등을 입력하여, 충족해야 할 소비 칼로리 및 식단을 관리할 수 있도록 도와줍니다.
                      </p>
                    </v-card-text>
                  </div>
                </v-col>

                <v-col md="4" cols="12">
                  <div>
                    <v-img src="https://s3.ap-northeast-2.amazonaws.com/images.wintersleeping/weightloss.jpeg" />
                    <v-card-title class="headline">
                      체중변화 관리
                    </v-card-title>
                    <v-card-text>
                      <p>
                      매일 기록한 체중과 칼로리 변동량을 통해 예측한 체중 그래프를 통해, 현재 체중 증, 감량의 상황을 파악할 수 있습니다.
                      </p>
                    </v-card-text>
                  </div>
                </v-col>
                <v-col md="4" cols="12">
                  <div>
                    <v-img src="https://s3.ap-northeast-2.amazonaws.com/images.wintersleeping/supplement.jpeg" />
                    <v-card-title class="headline">
                      보충제, 닭가슴살 선택
                    </v-card-title>
                    <v-card-text>
                      <p>
                      동면중이 추천하는 보충제 및 닭가슴살을 나열하고, 상황에 맞게 선택할 수 있도록 도와줍니다.
                      </p>
                    </v-card-text>
                  </div>
                </v-col>

              </v-row>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import axios from 'axios'
import api_conf from '../config/config.json'

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      limited: "limited",
      data: "data",
      lvl: "lvl",
    })
  },
  data: () => ({
    macro_pic: require('@/assets/macro1.png'),
    weight_pic: require('@/assets/weight.png'),
    notice: [],
    length: 3,
    window: 0,
  }),
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  mounted: async function () {
    this.load_init();
    // this.arrange()
  },
  methods: {
    load_init: async function() {
      let notice_ = await this.$firestore.collection('notice').limit(1).get();

      for ( let each of notice_.docs) {
        this.notice.push({ id:each.id, data:each.data()});
      }

    },
    // async arrange () {
    //   // const notice = await axios.get(
    //   //   api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/notice/', {
    //   // });

    //   // if ( notice.data.length > 0 ){
    //   //   for ( let i in notice.data ) {
    //   //     if ( notice.data[i].post_status == true ){
    //   //       this.notice_one.title = notice.data[i].title
    //   //       this.notice_one.content = notice.data[i].content
    //   //       this.notice_one.date = notice.data[i].date
    //   //       this.notice_one.br_content = (notice.data[i].content).split('\n').join('<br/>')
    //   //       this.notice_list.push(this.notice_one)
    //   //     }
    //   //   }
    //   // }
    // },
    // show_notice_one(item) {
    //   if ( item ) {
    //     this.notice_one.title = item.title
    //     this.notice_one.content = item.content
    //     this.notice_one.date = item.date
    //   }
    //   this.notice_dialog = true
    // },
    // close_notice_dialog() {
    //   this.notice_dialog = false
    //  // this.arrange()
    // },
  }
}
</script>

<style scoped>
/*
.v-card.doc {
  border-left: 1px solid #dddddd !important;
}
*/

.main_image {
  display: flex;
  height:100vh;
  align-items: center;
  justify-content: center;
  /*background-color: #28334AFF;*/
}

.main_desc {
  z-index: 1;
  margin-top: 50px;
  height:calc(100% - 250px);
  /*background-color: #28334AFF;*/
}

.center {
  margin: auto;
  padding-bottom: 60px;
  font-size: 3em;
  font-weight:bold;
}

.center2 {
  margin: auto;
  padding-bottom: 60px;
  font-size: 1.5em;
  font-weight:bold;
}

/*
.img {
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}
*/

</style>
